import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Role-based Navigation",
  "subtitle": "at Contently, 2017-2019",
  "tags": "Design, Front-end",
  "summary": "Reworked the information architecture for navigating our platform to help users complete their tasks more efficiently.",
  "date": "2018 -2019",
  "order": 2,
  "featuredImage": "./usernav/cover.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "about-the-project"
    }}>{`About the project`}</h2>
    <p>{`Contently helps brands create accountable content at scale with an end-to-end content marketing platform. We provided tools to manage workflow across teams, own their editorial calendars, get insights on publications, etc.`}</p>
    <h3 {...{
      "id": "the-challenge"
    }}>{`The challenge`}</h3>
    <p>{`Our navigation flow was inconsistent and not intuitive for many users who were looking to complete tasks quickly. It was not obvious that certain features existed as they were buried under vague terminology. We had reports of users navigating by memorized URLs which showed the severity of the issue.`}</p>
    <p>{`I raised concerns early on but the scope and multiple changes in personnel prevented much progression. A few of my colleagues and I who felt strongly on this subject pitched an initiative to allot time towards an incremental solution when our engineering department changed to self-structured teams.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1400px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Before & After",
          "title": "Before & After",
          "src": "/static/9cab1e08b03be16efd4fb068f7c4d738/6e6b4/main.png",
          "srcSet": ["/static/9cab1e08b03be16efd4fb068f7c4d738/a3397/main.png 400w", "/static/9cab1e08b03be16efd4fb068f7c4d738/a331c/main.png 800w", "/static/9cab1e08b03be16efd4fb068f7c4d738/6e6b4/main.png 1400w"],
          "sizes": "(max-width: 1400px) 100vw, 1400px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "team"
    }}>{`Team`}</h3>
    <ul>
      <li parentName="ul">{`Product Design (me)`}</li>
      <li parentName="ul">{`2+ Engineers (including me)`}</li>
      <li parentName="ul">{`UX Designer`}</li>
      <li parentName="ul">{`UX Researcher (former) - our project was largely based on her analysis`}</li>
    </ul>
    <h2 {...{
      "id": "user-flow-problems"
    }}>{`User flow problems`}</h2>
    <p>{`Early on, we built many features in silo and our information architecture decisions became driven by product teams wanting feature visibility rather than user preferences or their access levels. It became a problem to prioritize the limited real estate available in our navigation.`}</p>
    <h3 {...{
      "id": "unclear-top-level-navigation"
    }}>{`Unclear top-level navigation`}</h3>
    <p>{`We had user roles ranging from all access admins, content only contributors, and free portfolio users within our network. Our inconsistent decision making lead to an expanding set of links in our navigation unrelated to a user's role and also displayed in no particular order.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1340px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "User features",
          "title": "User features",
          "src": "/static/aae8e01e961a30701ba5803cf766723a/77a0f/userflow-user.png",
          "srcSet": ["/static/aae8e01e961a30701ba5803cf766723a/a3397/userflow-user.png 400w", "/static/aae8e01e961a30701ba5803cf766723a/a331c/userflow-user.png 800w", "/static/aae8e01e961a30701ba5803cf766723a/77a0f/userflow-user.png 1340w"],
          "sizes": "(max-width: 1340px) 100vw, 1340px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "inconsistent-access"
    }}>{`Inconsistent access`}</h3>
    <p>{`We originally only had the concept of `}<strong parentName="p">{`Publications`}</strong>{` which were client accounts. Purchased services determined feature activation and a user could belong to many publications. An account switcher was implemented for easy access between them.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1105px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Publication switching",
          "title": "Publication switching",
          "src": "/static/03a30e23a2d4bf621beb1275d9aa6007/3fc57/userflow-pubs-only.png",
          "srcSet": ["/static/03a30e23a2d4bf621beb1275d9aa6007/a3397/userflow-pubs-only.png 400w", "/static/03a30e23a2d4bf621beb1275d9aa6007/a331c/userflow-pubs-only.png 800w", "/static/03a30e23a2d4bf621beb1275d9aa6007/3fc57/userflow-pubs-only.png 1105w"],
          "sizes": "(max-width: 1105px) 100vw, 1105px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`We created `}<strong parentName="p">{`organizations`}</strong>{` to umbrella multiple publications that belonged together. Our clients used the concept in different ways to self-structure, but most of our users belonged to 1 organization and several publications.`}</p>
    <p><strong parentName="p">{`Our switcher menu was not universal.`}</strong>{`. Legacy features generally had a publication-only switcher while newer projects were fitted with an organization level switcher.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1027px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Organizations switching",
          "title": "Organizations switching",
          "src": "/static/89ad69bcb0a44c771e498b6bb9aaf8c3/90726/userflow-switcher.png",
          "srcSet": ["/static/89ad69bcb0a44c771e498b6bb9aaf8c3/a3397/userflow-switcher.png 400w", "/static/89ad69bcb0a44c771e498b6bb9aaf8c3/a331c/userflow-switcher.png 800w", "/static/89ad69bcb0a44c771e498b6bb9aaf8c3/90726/userflow-switcher.png 1027w"],
          "sizes": "(max-width: 1027px) 100vw, 1027px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`The list of `}<strong parentName="p">{`publications were also dynamic based on access to a page’s feature instead of the organization`}</strong>{`. Our user often had to navigate to a shared feature in order to use the menu as intended.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1159px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Switcher confusion",
          "title": "Switcher confusion",
          "src": "/static/0ddf2810a75e6761d3187c06995824f9/a2ee4/userflow-switcher-issues.png",
          "srcSet": ["/static/0ddf2810a75e6761d3187c06995824f9/a3397/userflow-switcher-issues.png 400w", "/static/0ddf2810a75e6761d3187c06995824f9/a331c/userflow-switcher-issues.png 800w", "/static/0ddf2810a75e6761d3187c06995824f9/a2ee4/userflow-switcher-issues.png 1159w"],
          "sizes": "(max-width: 1159px) 100vw, 1159px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "hidden-access-to-global-features"
    }}>{`Hidden access to global features`}</h3>
    <p>{`Our interface provided `}<strong parentName="p">{`no emphasis on global actions`}</strong>{` such as creating tags or editing users across an organization. These setting was only accessible by selecting “All publications” in the switcher but this didn't always appear. Since we provided no context or guidance, it resulted in an underutilization of these features.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1225px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hidden organization settings",
          "title": "Hidden organization settings",
          "src": "/static/6cc7cf0eefc99f5e16e2c6a89b244179/49149/userflow-org.png",
          "srcSet": ["/static/6cc7cf0eefc99f5e16e2c6a89b244179/a3397/userflow-org.png 400w", "/static/6cc7cf0eefc99f5e16e2c6a89b244179/a331c/userflow-org.png 800w", "/static/6cc7cf0eefc99f5e16e2c6a89b244179/49149/userflow-org.png 1225w"],
          "sizes": "(max-width: 1225px) 100vw, 1225px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "navigation-rework-design"
    }}>{`Navigation rework design`}</h2>
    <p>{`Our features had different structural needs. Invoices need not consolidate per organization while we found features like tags and user management more useful at the organization level. Our former UX researcher had done an analysis on our platform’s information architecture so we used the suggested reorganization as the basis for our rework.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1340px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Reorganized navigation",
          "title": "Reorganized navigation",
          "src": "/static/85c9f5958130388016c4040bdaebc78f/77a0f/new-v1.png",
          "srcSet": ["/static/85c9f5958130388016c4040bdaebc78f/a3397/new-v1.png 400w", "/static/85c9f5958130388016c4040bdaebc78f/a331c/new-v1.png 800w", "/static/85c9f5958130388016c4040bdaebc78f/77a0f/new-v1.png 1340w"],
          "sizes": "(max-width: 1340px) 100vw, 1340px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "fallback-design"
    }}>{`Fallback design`}</h3>
    <p>{`To prevent workflow disruption, and to allow for partial rollout with user testing, an inbetween version was included in our implementation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1340px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "17.750000000000004%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Navigation fallback",
          "title": "Navigation fallback",
          "src": "/static/ee8fa8d4bdac7d679df840c45550e8ab/77a0f/new-v1-fallback.png",
          "srcSet": ["/static/ee8fa8d4bdac7d679df840c45550e8ab/a3397/new-v1-fallback.png 400w", "/static/ee8fa8d4bdac7d679df840c45550e8ab/a331c/new-v1-fallback.png 800w", "/static/ee8fa8d4bdac7d679df840c45550e8ab/77a0f/new-v1-fallback.png 1340w"],
          "sizes": "(max-width: 1340px) 100vw, 1340px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "a-reusable-component"
    }}>{`A reusable component`}</h3>
    <p>{`Previously, our navigation was deeply linked to our monolithic repository but in our rework, we wanted to create more flexibility. We wanted to be able to reuse the design across multiple single page applications so we developed this project as a component in our `}<a parentName="p" {...{
        "href": "/work/designsystem"
      }}>{`design system`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1180px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "121.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Initial component",
          "title": "Initial component",
          "src": "/static/d155ef34cfda3c9bb11d868d37ce5192/0bd9b/new-component.png",
          "srcSet": ["/static/d155ef34cfda3c9bb11d868d37ce5192/a3397/new-component.png 400w", "/static/d155ef34cfda3c9bb11d868d37ce5192/a331c/new-component.png 800w", "/static/d155ef34cfda3c9bb11d868d37ce5192/0bd9b/new-component.png 1180w"],
          "sizes": "(max-width: 1180px) 100vw, 1180px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "loading-visual"
    }}>{`Loading visual`}</h3>
    <p>{`Since components were loaded externally and not immediately rendered, we needed a loading animation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1340px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "14.250000000000002%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Loading navigation",
          "title": "Loading navigation",
          "src": "/static/d3cff5eb479881e2a0260fcfba69c833/77a0f/new-loading.png",
          "srcSet": ["/static/d3cff5eb479881e2a0260fcfba69c833/a3397/new-loading.png 400w", "/static/d3cff5eb479881e2a0260fcfba69c833/a331c/new-loading.png 800w", "/static/d3cff5eb479881e2a0260fcfba69c833/77a0f/new-loading.png 1340w"],
          "sizes": "(max-width: 1340px) 100vw, 1340px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "icon-clarity"
    }}>{`Icon clarity`}</h3>
    <p>{`The analysis included chosen icons but they felt unclear on their own. We had considered swapping icons but I suggested we add headings to our menus to solve our pain point. If the results were still unclear, then we would conduct additional research for more  suitable iconography.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Icon clarity",
          "title": "Icon clarity",
          "src": "/static/c51eaff1cf15120d9049cfe4ab7af1c4/16546/new-icons.png",
          "srcSet": ["/static/c51eaff1cf15120d9049cfe4ab7af1c4/a3397/new-icons.png 400w", "/static/c51eaff1cf15120d9049cfe4ab7af1c4/16546/new-icons.png 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`I also used the header to cut down text for easy skimming in our “Create” menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Minimizing the create menu",
          "title": "Minimizing the create menu",
          "src": "/static/ea329f039a783d1bfa33107d02fb3371/16546/new-create.png",
          "srcSet": ["/static/ea329f039a783d1bfa33107d02fb3371/a3397/new-create.png 400w", "/static/ea329f039a783d1bfa33107d02fb3371/16546/new-create.png 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "organizing-content"
    }}>{`Organizing content`}</h3>
    <p>{`During the analytics, several new features were developed and we needed a point of access. Our decision was to group them into the most relevant menu and revisit after more user testing.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Organizing related content",
          "title": "Organizing related content",
          "src": "/static/a1c5114cd3d26bb94aea93ac5427876a/16546/new-related.png",
          "srcSet": ["/static/a1c5114cd3d26bb94aea93ac5427876a/a3397/new-related.png 400w", "/static/a1c5114cd3d26bb94aea93ac5427876a/16546/new-related.png 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "accessing-hidden-global-features"
    }}>{`Accessing hidden global features`}</h3>
    <p>{`These features weren't included in the analysis and was different problem altogether. I suggested we linked to it as a compromise to prevent our scope from ballooning. For a few minutes of work, we now had a way to access the previously buried feature. Small effort, big gain.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.74999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Organization level settings",
          "title": "Organization level settings",
          "src": "/static/3ea7aa3bc3afcbcf85ec1121e61ca44b/16546/new-org.png",
          "srcSet": ["/static/3ea7aa3bc3afcbcf85ec1121e61ca44b/a3397/new-org.png 400w", "/static/3ea7aa3bc3afcbcf85ec1121e61ca44b/16546/new-org.png 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "dual-navigation-design"
    }}>{`Dual navigation design`}</h2>
    <p>{`The reorganized structure depended on both user and account permissions so it made sense to use our secondary navigation as the primary driver in determining content availability.`}</p>
    <h3 {...{
      "id": "user-testing"
    }}>{`User testing`}</h3>
    <p>{`My colleague and I conducted a few live user testings internally to find a better placement for the context switcher. We asked them to complete tasks that required accessing these options.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1060px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "35%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dual navigation testing",
          "title": "Dual navigation testing",
          "src": "/static/afc72843b2478b4f48ead4679dc6bc5f/a48d4/new-multi-testing.png",
          "srcSet": ["/static/afc72843b2478b4f48ead4679dc6bc5f/a3397/new-multi-testing.png 400w", "/static/afc72843b2478b4f48ead4679dc6bc5f/a331c/new-multi-testing.png 800w", "/static/afc72843b2478b4f48ead4679dc6bc5f/a48d4/new-multi-testing.png 1060w"],
          "sizes": "(max-width: 1060px) 100vw, 1060px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "incremental-solution-to-inconsistent-access"
    }}>{`Incremental solution to inconsistent access`}</h3>
    <p>{`There were many potential solutions to solving the problem that required a huge time investment. I chose an interim solution to display all accessible publications in our context switcher but to visually disable them. It would at least communicate to our users that they had access.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Disabled options",
          "title": "Disabled options",
          "src": "/static/72f3bcd88f6ee28082503cd73ee87dc4/9710b/new-disabled-pub.png",
          "srcSet": ["/static/72f3bcd88f6ee28082503cd73ee87dc4/a3397/new-disabled-pub.png 400w", "/static/72f3bcd88f6ee28082503cd73ee87dc4/a331c/new-disabled-pub.png 800w", "/static/72f3bcd88f6ee28082503cd73ee87dc4/9710b/new-disabled-pub.png 899w"],
          "sizes": "(max-width: 899px) 100vw, 899px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "final-design"
    }}>{`Final design`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1340px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dual navigation",
          "title": "Dual navigation",
          "src": "/static/347c147f4e66fafc632cea85848e378d/77a0f/new-multi.png",
          "srcSet": ["/static/347c147f4e66fafc632cea85848e378d/a3397/new-multi.png 400w", "/static/347c147f4e66fafc632cea85848e378d/a331c/new-multi.png 800w", "/static/347c147f4e66fafc632cea85848e378d/77a0f/new-multi.png 1340w"],
          "sizes": "(max-width: 1340px) 100vw, 1340px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "mobile-navigation-rework"
    }}>{`Mobile navigation rework`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1397px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.50000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mobile refresh",
          "title": "Mobile refresh",
          "src": "/static/bb88d500abe2b491b170573523355fac/f716d/new-mobile.png",
          "srcSet": ["/static/bb88d500abe2b491b170573523355fac/a3397/new-mobile.png 400w", "/static/bb88d500abe2b491b170573523355fac/a331c/new-mobile.png 800w", "/static/bb88d500abe2b491b170573523355fac/f716d/new-mobile.png 1397w"],
          "sizes": "(max-width: 1397px) 100vw, 1397px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "takeaway"
    }}>{`Takeaway`}</h2>
    <p>{`Many frustrations and problems were left unsolved but sometimes, we just have to start somewhere. By standardizing our navigation across the board, we were able to make incremental improvements. It gave us new prospective on the issues we had to tackle.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      